const KEYCLOAK_TOKEN_PARAMS_LIST = ['state', 'session_state', 'iss', 'code'];

export const isTokenRedirect = () =>
    KEYCLOAK_TOKEN_PARAMS_LIST.every((keycloak_token_param) =>
        window.location.search.includes(`${keycloak_token_param}=`),
    );

export const getCurrentURLWithoutKeycloakParams = () => {
    const currentURL = new URL(window.location.href);

    KEYCLOAK_TOKEN_PARAMS_LIST.forEach((keycloakParam) => currentURL.searchParams.delete(keycloakParam));
    return currentURL.toString();
};
