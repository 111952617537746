import { getCurrentURLWithoutKeycloakParams } from '@utils/keycloakUtils';
import { Mutex } from 'async-mutex';

interface IFetchApi {
    url: string;
    method?: string;
    credentials?: RequestCredentials;
    headers?: HeadersInit;
    signal?: AbortSignal | null;
    body?: Record<string, any>;
    metaInfo?: Record<string, any>;
    apiPrefix?: string;
}

const isAuthErrorCode = (code: number | string) =>
    typeof code === 'number' && (code === 401 || code === 403 || code === 500 || code === 502);

const mutex = new Mutex();

async function fetchApi({
    url,
    method = 'GET',
    credentials = 'include',
    headers,
    body,
    metaInfo,
    apiPrefix = '/top100',
}: IFetchApi) {
    const defaultHeaders = {
        Accept: 'application/json', // eslint-disable-line quote-props
        'Content-Type': 'application/json',
    };
    const apiUrl = `${__GATEWAY_URL__}${apiPrefix}${url}`;
    const authUrl = `${__GATEWAY_URL__}/auth?redirect_uri=${getCurrentURLWithoutKeycloakParams()}`;
    const refreshUrl = `${__GATEWAY_URL__}/refresh`;
    // const isDemoProject = window.location.pathname.includes(`/projects/${reportsConstants.DEMO_PROJECT_ID}/`);

    const options = {
        method,
        credentials,
        headers: headers || defaultHeaders,
        ...(body ? { body: JSON.stringify(body) } : {}),
    };

    let response = await fetch(apiUrl, options);
    const responseStatus = response.status;

    // Для демо проекта рефреши делать не нужно так как там не нужна авторизация
    if (!response.ok && isAuthErrorCode(responseStatus) /* && !isDemoProject */) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                const refreshResponse = await fetch(refreshUrl);
                if (!refreshResponse.ok && isAuthErrorCode(refreshResponse.status)) {
                    window.location.replace(authUrl);
                } else {
                    response = await fetch(apiUrl, options);
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            response = await fetch(apiUrl, options);
        }
    }

    if (responseStatus === 204) return responseStatus;

    const result = await response.json();

    return metaInfo ? { ...result, metaInfo, responseStatus } : { ...result, responseStatus };
}

export default fetchApi;
