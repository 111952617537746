import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISearchParams } from '@typings/search';
import fetchApi from '@utils/fetchApi';
import { urlUtils } from '@utils/index';

export const getUser = createAsyncThunk(
    'userSlice/getUser',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/user/current',
        }),
);

export const getUserProjects = createAsyncThunk('userSlice/getUserProjects', async (params: ISearchParams) => {
    let url = '/api/v1.0/user/current/projects';
    const query = urlUtils.objectToQuery(params);
    url = query ? `${url}?${query}` : url;
    // eslint-disable-next-line no-return-await
    return await fetchApi({ url });
});

export const getUserExists = createAsyncThunk(
    'userSlice/getUserExists',
    async (email: string) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/user/exists',
            method: 'POST',
            body: { email },
        }),
);

export const logout = createAsyncThunk(
    'userSlice/logout',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/logout',
            method: 'POST',
            apiPrefix: '',
        }),
);
