import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProjectsListRequestParams } from '@typings/projects';
import fetchApi from '@utils/fetchApi';
import { urlUtils } from '@utils/index';

export const getProject = createAsyncThunk(
    'projectsSlice/getProject',
    async ({ projectId, userId }: { projectId: string | number; userId?: string | number }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}`,
            metaInfo: { userId },
        }),
);

/**
 * Экшен на получение таблицы на страницах 'Мои сайты' и 'Удаленные'
 */
export const getProjectsTable = createAsyncThunk(
    'projectsSlice/getProjectsTable',
    async ({ lastReqId, ...params }: IProjectsListRequestParams) => {
        let url = '/api/report/v1.0/project/list';
        const query = urlUtils.objectToQuery(params);
        url = query ? `${url}?${query}` : url;

        // eslint-disable-next-line no-return-await
        return await fetchApi({
            url,
            metaInfo: { lastReqId },
        });
    },
);

/**
 * Экшен на удаление проекта из "моих сайтов"
 */
export const deleteProject = createAsyncThunk(
    'projectsSlice/deleteProject',
    async (projectId: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}`,
            method: 'DELETE',
            metaInfo: { projectId },
        }),
);

/**
 * Экшен на восстановление проекта из "моих сайтов"
 */
export const restoreProject = createAsyncThunk(
    'projectsSlice/restoreProject',
    async (projectId: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}/restore`,
            method: 'POST',
            metaInfo: { projectId },
        }),
);

/**
 * Получение каптчи
 */
export const getCaptcha = createAsyncThunk(
    'projectsSlice/getCaptcha',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: '/api/v1.0/project/is_captcha_needed' }),
);

/**
 * Получение методаты с названием сайта
 */
export const getMetadata = createAsyncThunk('projectsSlice/getMetadata', async (siteUrl: string) => {
    let url = '/api/v1.0/project/fetch_metadata';
    url = siteUrl ? `${url}?url=${siteUrl}` : url;

    // eslint-disable-next-line no-return-await
    return await fetchApi({
        url,
        metaInfo: {
            ignoredErrors: [400],
        },
    });
});

/**
 * Создание нового счётчика
 */
export const create = createAsyncThunk(
    'projectsSlice/create',
    async (body: Record<string, any>) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/project',
            method: 'POST',
            body,
            metaInfo: {
                ignoredErrors: [400],
            },
        }),
);

/**
 * Сохранение изменений проекта счётчика
 */
export const save = createAsyncThunk(
    'projectsSlice/save',
    async ({ id, data }: { id: number; data: Record<string, any> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${id}`,
            method: 'PUT',
            body: data,
        }),
);

// Не используется
export const remove = createAsyncThunk(
    'projectsSlice/remove',
    async (id: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${id}`,
            method: 'DELETE',
        }),
);

/**
 * Экшен получения конфига для отчёта (не используется)
 */
export const getConfig = createAsyncThunk(
    'projectsSlice/getConfig',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: '/api/project/config' }),
);

/**
 *  Получение счётчиков с таким же url, для восстановления
 */
export const getProjectsWithSameUrl = createAsyncThunk(
    'projectsSlice/getProjectsWithSameUrl',
    async (params: { url: string; limit?: number; offset?: number }) => {
        let url = '/api/v1.0/project/projects_with_same_url';
        const query = urlUtils.objectToQuery(params);
        url = query ? `${url}?${query}` : url;

        // eslint-disable-next-line no-return-await
        return await fetchApi({
            url,
            metaInfo: { url },
        });
    },
);

/**
 * Отправка выбранного id счётчика для восстановления
 */
export const restoreCounter = createAsyncThunk(
    'projectsSlice/restoreCounter',
    async (projectId: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}/verify_ownership`,
            method: 'POST',
        }),
);
