import { MainShape } from '@adtech/ui';
import { useAppDispatch } from '@hooks/useStore';
import { userActions } from '@redux/slices/users';
import { IRootSlice } from '@typings/rootSlice';
import { getCurrentURLWithoutKeycloakParams } from '@utils/keycloakUtils';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import s from './Header.pcss';

const Header: React.FC = () => {
    const dispatch = useAppDispatch();
    const email = useSelector((state: IRootSlice) => state.userSlice.email) || '';
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);
    const userName = useSelector((state: IRootSlice) => state.userSlice.userName);

    const authUrl = `${__GATEWAY_URL__}/auth?redirect_uri=${getCurrentURLWithoutKeycloakParams()}`;
    const { hostname } = window.location;
    let linkToProfile = 'https://ads.sber.ru/td/profile/';

    if (hostname.includes('ift') || hostname === 'localhost') {
        linkToProfile = 'https://perf-td-frontend.ift-mesh.ad-tech.ru/profile/';
    }

    const onLogout = () => {
        if (userId === null) {
            window.location.href = '/';
            return;
        }

        dispatch(userActions.logout()).then(() => {
            window.location.replace(authUrl);
        });
    };

    const onSettingsClick = () => window.open(linkToProfile, '_self');

    return (
        <div className={cn(s.root, { [s.notAuthorized]: !email })}>
            <MainShape
                additionalLogo="Статистика"
                logoLink="/stat/projects"
                onLogout={onLogout}
                menuProps={{
                    text: userName || email,
                    activeKey: 1,
                    onSettingsClick,
                    onLogOut: onLogout,
                }}
            />
        </div>
    );
};

export default Header;
