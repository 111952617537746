import authTypes from '@constants/authTypes';
import { getUser, getUserProjects, getUserExists, logout } from '@redux/slices/users/api';
import { createSlice } from '@reduxjs/toolkit';
import { IUserSlice } from '@typings/rootSlice';
import storagesUtils from '@utils/storagesUtils';

export const initialState: IUserSlice = {
    login: '',
    id: null,
    email: '',
    userName: '',
    loginRequest: false,
    userRequest: false,
    userRequestError: false,
    logged: false,
    authType: null,
    isTop100Staff: null,
    rolePermissions: {},
    roleId: null,
    snackbars: [],
    userProjects: [],
    isUserProjectsExpanded: false,
    loginAttempt: false,
    userProjectsRequest: false,
    userProjectsTotal: 0,
    metaTips: {
        reportAdditional: true,
        reportCsv: true,
        reportFilter: true,
        reportGraph: true,
        reportSettings: true,
        reportViews: 0,
    },
};

/**
 * Устанавливаем флаг о показе проектов пользователя
 */
const setUserProjectsExpandedFunc = (state: IUserSlice, action) => {
    const { id } = state;
    if (!id) return state;

    storagesUtils.set('isUserProjectsExpanded', action.payload, id);

    state.isUserProjectsExpanded = action.payload;
};

/**
 * Обнуляем данные, если запрос идет от пользователя
 */
const setRequest = (state: IUserSlice) => {
    state.userRequest = true;
};

const getUserProjectsExpanded = (userId) => {
    if (!userId) return false;
    const value = storagesUtils.get('isUserProjectsExpanded', userId);
    return value || false;
};

/**
 * Устанавливаем полученные о пользователе данные
 */
const setUser = (state: IUserSlice, action) => {
    const { data = {}, errors } = action.payload;

    if (errors) {
        state.loginRequest = false;
        state.userRequest = false;
        state.userRequestError = true;
        state.loginAttempt = true;
        state.authType = errors.auth_type || null;
        return;
    }

    const isView = data.metaTips?.reportViews > 3;

    Object.assign(state, {
        login: data.login || '',
        id: data.id || null,
        email: data.email || '',
        userName: data.user_name || '',
        loginRequest: data.loginRequest || false,
        userRequestError: data.userRequestError || false,
        authType: data.auth_type || authTypes.KEYCLOAK,
        isTop100Staff: data.is_top100_staff,
        rolePermissions: data.role_permissions || {},
        roleId: data.role_id || null,
        snackbars: data.snackbars || [],
        userProjects: data.userProjects || [],
        isUserProjectsExpanded: getUserProjectsExpanded(data.id),
        userProjectsRequest: data?.userProjectsRequest || false,
        // prettier-ignore
        metaTips: isView ? {
            reportAdditional: false,
            reportCsv: false,
            reportFilter: false,
            reportGraph: false,
            reportSettings: false,
            reportViews: 3,
        } : (data.metaTips || {
            reportAdditional: true,
            reportCsv: true,
            reportFilter: true,
            reportGraph: true,
            reportSettings: true,
            reportViews: 0,
        }),
        logged: true,
        userRequest: false,
        loginAttempt: true,
    });
};

/**
 * Устанавливаем флаг об ошибке запроса
 */
const setFailureRequest = (state: IUserSlice) => {
    state.userRequest = false;
    state.userRequestError = true;
    state.loginAttempt = true;
};

/**
 * Ожидание проектов пользователя
 */
const setRequestUserProjects = (state: IUserSlice) => {
    state.userProjectsRequest = true;
    state.userProjects = [];
};

/**
 * Устанавливаем полученные проекты пользователя
 */
const setUserProjects = (state: IUserSlice, action) => {
    const { data, meta } = action.payload;
    const userProjects = data.map((item) => item.project);

    state.userProjectsTotal = meta.total_rows;
    state.userProjectsRequest = false;
    state.userProjects = userProjects;
};

/**
 * Устанавливаем флаг об ошибке запроса проектов
 */
const setFailureUserProjects = (state: IUserSlice) => {
    state.userProjectsRequest = false;
    state.userProjects = initialState.userProjects;
};

/**
 * Удаляем данные при выходе юзера
 */
const setLogout = () => initialState;

const usersSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUserProjectsExpanded: (state, action) => setUserProjectsExpandedFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => setRequest(state))
            .addCase(getUser.fulfilled, (state, action) => setUser(state, action))
            .addCase(getUser.rejected, (state) => setFailureRequest(state))

            .addCase(logout.fulfilled, () => setLogout())

            .addCase(getUserProjects.pending, (state) => setRequestUserProjects(state))
            .addCase(getUserProjects.fulfilled, (state, action) => setUserProjects(state, action))
            .addCase(getUserProjects.rejected, (state) => setFailureUserProjects(state));
    },
});

export const { setUserProjectsExpanded } = usersSlice.actions;

export const userActions = {
    setUserProjectsExpanded,
    getUser,
    getUserProjects,
    logout,
    getUserExists,
};

export default usersSlice.reducer;
