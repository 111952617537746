import { createAsyncThunk } from '@reduxjs/toolkit';
import { IDashboardRequestParams } from '@typings/dashboard';
import { IReportRequestParams } from '@typings/reports';
import fetchApi from '@utils/fetchApi';

export const getEventBasedDashBoardWidgets = createAsyncThunk(
    'dashboardSlice/getEventBasedDashBoardWidgets',
    async ({
        projectId,
        requestId,
        isAutoUpdate = false,
        body,
    }: {
        projectId: string;
        requestId: number;
        isAutoUpdate?: boolean;
        body?: IDashboardRequestParams;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/realtime`,
            method: 'POST',
            body,
            metaInfo: {
                ignoreAllErrors: true,
                requestId,
                isAutoUpdate,
            },
        }),
);

export const getEventsList = createAsyncThunk(
    'dashboardSlice/getEventsList',
    async ({ projectId, params }: { projectId: string; params?: Partial<IReportRequestParams> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/filter/events`,
            method: 'POST',
            body: params,
        }),
);
