import { Loader } from '@adtech/ui';
import Footer from '@components/Footer';
import Header from '@components/Header';
import Sidebar from '@components/Sidebar';
// import reportsConstants from '@constants/reports';
import { Error } from '@containers/Error';
import ModalNotification from '@containers/ModalNotification';
import ModalPopup from '@containers/ModalPopup';
import PopupNotification from '@containers/PopupNotification';
import SnackbarNotification from '@containers/SnackbarNotification';
import useModalNotification from '@hooks/useModalNotification';
import { useAppSelector, useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { userActions } from '@redux/slices/users';
import { setAdBlockNotification } from '@utils/checkAdBlock';
import top100Counter from '@utils/top100Counter';

import cn from 'classnames';
import React, { useEffect, useCallback, useRef } from 'react';
import { useLocation, useParams, Outlet } from 'react-router-dom';

import s from './Layout.pcss';

const Layout: React.FC = () => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const { reportName }: IUrlParams = useParams();

    const { setNotification } = useModalNotification();

    const errorCode = useAppSelector((state) => state.globalSlice.error?.code || null);
    const globalLoading = useAppSelector((state) => state.globalSlice.globalLoading);
    const loginAttempt = useAppSelector((state) => state.userSlice.loginAttempt);
    const userId = useAppSelector((state) => state.userSlice.id);
    const userEmail = useAppSelector((state) => state.userSlice.email);
    const authType = useAppSelector((state) => state.userSlice.authType);
    // const isDemoProject = pathname.includes(`/projects/${reportsConstants.DEMO_PROJECT_ID}/`);

    const sendPageView = () => {
        window.top100Counter?.trackPageview();
        window.top100CounterSber?.trackPageview();
    };

    const initCountersAuth = useCallback(() => {
        top100Counter.setAuthId(userId, userEmail);
    }, [userId, userEmail]);

    useEffect(() => {
        dispatch(userActions.getUser());
        dispatch(globalActions.getServerTime());
        setAdBlockNotification(setNotification);

        // Инициализируем счетчик
        top100Counter.setPubId();
    }, []);

    const mounted = useRef(false);

    // Инициализируем передачу id авторизации
    // Отправляем PV только при смене pathname
    useEffect(() => {
        if (loginAttempt) {
            if (!mounted.current) {
                initCountersAuth();
                mounted.current = true;
            } else {
                sendPageView();
            }
        }
    }, [loginAttempt, pathname]);

    // TODO: решить что делаем с demo проектом и доправить
    const renderOutlet = () => {
        if (errorCode) return <Error />;

        if (userId === null /* && !isDemoProject */) return <Loader loading className={s.loaderLarge} />;

        return (
            <Loader loading={globalLoading} className={s.loader}>
                <Outlet />
            </Loader>
        );
    };

    // Используем костыль класс scrollable-root для global-tint
    // чтобы перекрыть ui-kit поведение и оставить скролл у подложки
    return (
        <div className={s.root}>
            <Header />
            <div className={s.layout}>
                <div className={s.main}>
                    <Sidebar authType={authType} />
                    <main className={cn(s.content, 'scrollable-root')}>{renderOutlet()}</main>
                </div>
                <Footer reportName={reportName} />
            </div>
            <ModalPopup />
            <SnackbarNotification />
            <PopupNotification />
            <ModalNotification />
        </div>
    );
};

export default Layout;
