import { Button, IconSettings } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import useCopy from '@hooks/useCopy';
import IconCopySmall from '@images/svg/icons/icon-copy-small.svg';
import { IRootSlice } from '@typings/rootSlice';
import React, { useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import s from './ProjectPanel.pcss';
import ProjectsModal from './modals/ProjectsModal';

const ProjectPanel: FC = () => {
    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);
    const [isProjectsModalOpen, setProjectsModalOpen] = useState(false);
    const cleanedUrl = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const projectUrl = useSelector((state: IRootSlice) => state.projectsSlice.current.url);
    const urlAttr = attributesData.topline.url;
    const counterLinkAttr = attributesData.topline.counterLink;
    const counterSettingsAttr = attributesData.topline.counterSettings;
    const fullAccess = useSelector((state: IRootSlice) => state.projectsSlice.current.permissions.can_edit_project);
    const { copy } = useCopy();

    const copyHandler = (): void => {
        copy(projectId, 'Id проекта скопирован');
    };

    return (
        <div className={s.projectPanel}>
            <Button className={s.linkAllSites} type="link" onClick={() => setProjectsModalOpen(true)}>
                Мои сайты
            </Button>
            <span className={s.divider}>|</span>
            <a href={projectUrl} className={s.linkUrl} target="_blank" data-topline={urlAttr} rel="noopener">
                {cleanedUrl}
            </a>
            <div className={s.id}>
                <Link
                    className={s.idLink}
                    data-topline={counterLinkAttr}
                    to={`/stat/projects/${projectId}/${fullAccess ? 'settings/counter' : 'dashboard'}`}
                >
                    {projectId}
                </Link>
                <Button onClick={copyHandler} className={s.idButtonCopy} type="link" icon={<IconCopySmall />} />
                {fullAccess && (
                    <Button
                        className={s.idButtonSettings}
                        type="link"
                        href={`/stat/projects/${projectId}/settings/counter`}
                        icon={<IconSettings />}
                        data-topline={counterSettingsAttr}
                    />
                )}
            </div>
            <ProjectsModal
                isOpen={isProjectsModalOpen}
                projectId={projectId}
                onCloseHandler={() => setProjectsModalOpen(false)}
            />
        </div>
    );
};

export default ProjectPanel;
