import store from '@redux/store';
import { getCurrentURLWithoutKeycloakParams, isTokenRedirect } from '@utils/keycloakUtils';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRouter from './routes';

const App = (): React.ReactNode => {
    if (isTokenRedirect()) {
        window.location.replace(
            `${__GATEWAY_URL__}/token${window.location.search}&redirect_uri=${getCurrentURLWithoutKeycloakParams()}`,
        );
        return '';
    }

    return (
        <Provider store={store}>
            <Router>
                <AppRouter />
            </Router>
        </Provider>
    );
};

export default App;
